<template>
  <b-card class="card-transaction shadow-none" no-body>
    <b-card-header class="my-1">
      <b-card-title
        >{{ crName }} -
        <i> ({{ crAmount }}) </i>
      </b-card-title>
      <!-- dropdown actions -->
      <!--        <b-dropdown variant="link" toggle-class="p-0" no-caret right>-->
      <!--          <template #button-content>-->
      <!--            <feather-icon-->
      <!--              icon="MoreVerticalIcon"-->
      <!--              size="18"-->
      <!--              class="cursor-pointer"-->
      <!--            />-->
      <!--          </template>-->
      <!--          <b-dropdown-item variant="danger">-->
      <!--            <feather-icon icon="ToggleLeftIcon" />-->
      <!--            <span class="align-middle ml-50">Désactiver</span>-->
      <!--          </b-dropdown-item>-->
      <!--        </b-dropdown>-->
    </b-card-header>

    <div v-if="loading" class="d-flex justify-content-center my-3">
      <b-spinner style="width: 4rem; height: 4rem" />
    </div>
    <b-card-body v-if="hasSubCR && !loading">
      <div
        v-for="subCashRegister in cashRegister.sub_cash_registers"
        :key="subCashRegister.uid"
        class="transaction-item pb-1 border-bottom"
      >
        <!-- Fund type -->
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="
                resolveSubCashRegisterIcon(subCashRegister).avatarVariant
              "
            >
              <feather-icon
                size="18"
                :icon="resolveSubCashRegisterIcon(subCashRegister).avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ subCashRegister.name }}
            </h6>
            <small>{{ amountToCurrency(subCashRegister.amount) }}</small>
          </b-media-body>
        </b-media>

        <!-- Action button -->
        <div class="font-weight-bolder">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon rounded"
            @click="$emit('toggle-component','Providing Form', subCashRegister)"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Approvisionner</span>
          </b-button>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";


import Ripple from "vue-ripple-directive";
import { amountToCurrency } from "@/utils/formatAmount";
import store from "@/store";

export default {
  name: "CashRegisterDetailsModalSubList",
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BButton,
    // BDropdown,
    // BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    loading: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    cashRegister() {
      return store.getters["fundHistory/cashRegister"];
    },
    crName() {
      return this.cashRegister ? this.cashRegister?.name : "_____";
    },
    crAmount() {
      if (this.cashRegister) return amountToCurrency(this.cashRegister?.amount);
      return 0;
    },
    hasSubCR() {
      if (this.cashRegister) {
        return this.cashRegister.sub_cash_registers.length > 0;
      }
      return false;
    },
  },
  methods: {
    amountToCurrency,
    resolveSubCashRegisterIcon(cashRegister) {
      const type = cashRegister.fund_type?.slug;
      switch (type) {
        case "coffre_fort":
          return {
            avatar: "PocketIcon",
            avatarVariant: "light-primary",
          };
        case "banque":
          return {
            avatar: "CreditCardIcon",
            avatarVariant: "light-success",
          };
        case "mobile_money":
          return {
            avatar: "CreditCardIcon",
            avatarVariant: "light-danger",
          };
        case "bon_en_essence":
          return {
            avatar: "SmartphoneIcon",
            avatarVariant: "light-warning",
          };
        default:
          return {
            avatar: "TagIcon",
            avatarVariant: "light-info",
          };
      }
    },
  },
};
</script>
