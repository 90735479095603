<template>
  <b-modal
    id="cash-register-modal"
    v-model="toggleCashRegisterDetailsModal"
    size="lg"
    ok-only
    ok-title="Fermer"
    centered
    title="Détails Caisse"
  >
    <component
      :is="currentComponent"
      :loading.sync="isLoading"
      @toggle-component="setCashRegisterDetailsComponent"
      @cash-register-handled="showToast"
    />
  </b-modal>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";

import ToastNotificationContent from "@core/components/toastification/ToastificationContent.vue";
import CashRegisterDetailsModalSubList
  from "@/views/apps/fund-history/fund-histories-list/cash-register/cash-register-details-modal/CashRegisterDetailsModalSubList.vue";
import CashRegisterDetailsModalProvidingForm
  from "@/views/apps/fund-history/fund-histories-list/cash-register/cash-register-details-modal/CashRegisterDetailsModalProvidingForm.vue";

export default {
  name: "cashRegisterDetailsModal",
  components: {
    BModal,

    CashRegisterDetailsModalSubList,
    CashRegisterDetailsModalProvidingForm,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    toggleModal: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      isLoading: false,
      optionsComponent: "Sub List",
    };
  },
  watch: {
    toggleCashRegisterDetailsModal: {
      handler: function (isOpened) {
        if (!isOpened) this.optionsComponent = "Sub List";
      },
      immediate: true,
    },
  },
  computed: {
    toggleCashRegisterDetailsModal: {
      get: function () {
        return this.toggleModal;
      },
      set: function (val) {
        this.$emit("update:toggleModal", val);
      },
    },
    currentComponent() {
      const slug = this.optionsComponent.replaceAll(" ", "-").toLowerCase();
      return "cash-register-details-modal-" + slug;
    },
  },
  methods: {
    setCashRegisterDetailsComponent(component, selectedSubCR) {
      // check if component or selected sub cash register is not null or undefined
      if(!component || !selectedSubCR) {
        this.showToast("La sous caisse rencontre un souci avec ses identifiants.", "AlertTriangleIcon", "danger");
        return;
      }
      this.$store.commit("fundHistory/SET_SUB_CASH_REGISTER_INFOS", selectedSubCR); // set selected sub cash register
      this.optionsComponent = component;
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastNotificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    },
  },
};
</script>
