<template>
  <validation-observer ref="refProvidingFormObserver" #default="{ invalid }">
    <b-form @submit.prevent="saveProviding" @reset="resetForm">
      <b-row class="mb-2">
        <b-col cols="6">
          <!-- label -->
          <validation-provider
            #default="{ errors }"
            name="Motif"
            rules="required"
          >
            <b-form-group
              label="Intitulé de la sous-caisse"
              label-for="label"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                :value="subCR.name"
                id="label"
                type="text"
                placeholder="Intitulé de la sous-caisse"
                :state="errors.length > 0 ? false : null"
                disabled
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <!-- providing account -->
          <validation-provider
            #default="{ errors }"
            name="account"
            rules="required"
          >
            <b-form-group
              label="Choix du compte d'approvisionnement"
              label-for="account"
            >
              <v-select
                v-model="providingData.account"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accountsList"
                :reduce="(o) => o.uid"
                :clearable="false"
                placeholder="Sélectionnez ou Recherchez le compte"
                input-id="account"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche un compte...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- amount -->
      <validation-provider
        #default="{ errors }"
        name="Montant"
        :rules="`required|min-amount:0|max-amount:${selectedAccount ? selectedAccount.amount : 1}`"
      >
        <b-form-group
          label-for="providing-amount"
          :state="errors.length > 0 ? false : null"
        >
          <template #label>
            <span class="float-left">Montant d'approvisionnement (XOF)</span>
            <i class="float-right"
              >Solde Restant en Compte :<span class="ml-1">{{
                amountToCurrency(remainAccount)
              }}</span></i
            >
          </template>
          <b-form-input
            v-model="providingData.amount"
            id="providing-amount"
            type="number"
            placeholder="Le montant de paiement"
            :state="errors.length > 0 ? false : null"
          />
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- details -->
      <validation-provider #default="{ errors }" name="Détails">
        <b-form-group
          label="Détails sur l'approvisionnement"
          label-for="details"
          :state="errors.length > 0 ? false : null"
        >
          <b-form-textarea
            v-model="providingData.details"
            id="details"
            :state="errors.length > 0 ? false : null"
          />
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="mt-3">
        <hr />
        <b-button
          size="sm"
          class="mr-1"
          type="submit"
          variant="primary"
          :disabled="loading || invalid"
        >
          <b-spinner v-if="loading" small />
          Enregistrer
        </b-button>
        <b-button
          size="sm"
          type="reset"
          variant="outline-secondary"
          :disabled="loading"
          >Annuler
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";

import vSelect from "vue-select";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { createNamespacedHelpers } from "vuex";
import log from "echarts/src/scale/Log";
import { amountToCurrency } from "@/utils/formatAmount";

const { mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("fundHistory");

export default {
  name: "CashRegisterDetailsModalProvidingForm",
  components: {
    BRow,
    BCol,
    BForm,
    BSpinner,
    BButton,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormInput,

    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    loading: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      providingData: { amount: 0, details: "", account: null },
    };
  },
  watch: {
    getError: {
      handler: function (val) {
        if (val) {
          this.$emit(
            "cash-register-handled",
            val.message,
            "AlertTriangleIcon",
            "danger"
          );
          this.handleErrors(val.error);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      subCR: "selectedSubCR",
      accountsList: "accounts",
      getError: "errors",
    }),
    selectedAccount() {
      return this.accountsList.find(
        (a) => a.uid === this.providingData.account
      );
    },
    remainAccount() {
      let currAmount = 0;
      if (this.selectedAccount) currAmount = this.selectedAccount.amount;
      currAmount -= this.providingData.amount;

      return currAmount;
    },
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    amountToCurrency,
    ...mapActions(["loadCashRegister", "getAccounts", "addProvision"]),
    ...mapMutations({
      setError: "SET_ERRORS",
    }),
    handleErrors(errors) {
      for (const [field, message] of Object.entries(errors)) {
        this.$refs.refProvidingFormObserver.setErrors({ [field]: message[0] });
      }
    },
    async saveProviding() {
      this.$emit("update:loading", true);
      this.setError(null);
      try {
        await this.addProvision({
          uid: this.subCR.uid,
          data: this.providingData,
        });
        this.$emit(
          "cash-register-handled",
          "L'approvisionnement a été enregistré avec succès.",
          "AlertTriangleIcon",
          "success"
        );
        this.resetForm();
      } catch (e) {
        //
      } finally {
        this.setError(null);
        this.$emit("update:loading", false);
        await this.loadCashRegister();
        this.$emit("toggle-component", "Sub List", this.subCR);
      }
    },
    resetForm() {
      this.providingData = { amount: 0, details: "", account: null };
    },
  },
};
</script>
