import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import axios from "@axios";
import { useToast } from "vue-toastification/composition";

export default function useFundHistoryList() {
  // Use toast
  const toast = useToast();
  const expenses = ref([]);

  const refFundHistoryListTable = ref(null);
  const refReqListTable = ref(null);
  const refApprListTable = ref(null);

  const fundHistoryFields = [
    {
      key: "id",
      label: "Id",
    },
    { key: "label", label: "Désignation", sortable: true },
    { key: "debit", label: "Sortie", sortable: true },
    { key: "credit", label: "Entrée", sortable: true },
    { key: "sub_cr", label: "Sous-caisse" },
    { key: "balance", label: "Solde" },
    { key: "type", label: "Type" },
    { key: "made_by", label: "Effectuée Par", sortable: true },
    { key: "created_at", label: "Date", sortable: true },
  ];
  const requestsFields = [
    {
      key: "id",
      label: "Id",
    },
    { key: "designation", label: "Désignation" },
    { key: "amount", label: "Montant" },
    { key: "due_date", label: "Date Prévu", sortable: true },
    { key: "project", label: "Projet Concerné", sortable: true },
    { key: "made_by", label: "Effectuée Par", sortable: true },
    { key: "created_at", label: "Effectuée le", sortable: true },
    { key: "actions" },
  ];
  const approvedFields = [
    {
      key: "id",
      label: "Id",
    },
    { key: "designation", label: "Désignation" },
    { key: "approved_amount", label: "Montant Accepté" },
    { key: "total_paid", label: "Montant Payé" },
    { key: "due_date", label: "Date Prévu", sortable: true },
    { key: "project", label: "Projet Concerné", sortable: true },
    { key: "approved_by", label: "Approuvé Par", sortable: true },
    { key: "created_at", label: "Crée le", sortable: true },
    { key: "actions" },
  ];

  const showCashRegisterList = ref(false)


  //fund history pagination & filter params
  const fhPerPage = ref(5);
  const fhCurrentPage = ref(1);
  const fhPageOptions = ref([5, 10, 15, 20]);
  const fhFilter = ref("");
  const fhSortBy = ref("created_at");
  const fhSortDesc = ref(false);
  const fhSortDirection = ref("asc");

  //request pagination & filter params
  const reqPerPage = ref(5);
  const reqPageOptions = ref([5, 10, 15, 20]);
  const reqCurrentPage = ref(1);
  const reqSortBy = ref("");
  const reqSortDesc = ref(false);
  const reqSortDirection = ref("asc");
  const reqFilter = ref("");
  const requests = ref([]);
  const totalReq = ref(0);

  //budget pagination & filter params
  const budgetPerPage = ref(5);
  const budgetPageOptions = ref([5, 10, 15, 20]);
  const budgetCurrentPage = ref(1);
  const budgetSortBy = ref("");
  const budgetSortDesc = ref(false);
  const budgetSortDirection = ref("asc");
  const budgetFilter = ref("");
  const budgets = ref([]);
  const totalBud = ref(0);

  const reqTotalAmount = ref(0);
  const budgetTotalAmount = ref(0);

  const fundHistories = computed(() => store.state.fundHistory.list);
  const balance = computed(() => store.getters["fundHistory/balance"]);
  const totalDebitAndCredit = computed(
    () => store.getters["fundHistory/transacs"]
  );
  const totalFundHistories = computed(
    () => store.getters["fundHistory/total"]
  );

  const countRequests = computed(
    () => totalReq.value
  );
  const countBudgets = computed(
    () => totalBud.value
  );
  const fundHistoryData = computed(() => {
    return {
      requests_total: reqTotalAmount.value,
      budgets_total: budgetTotalAmount.value,
    };
  });

  const refetchFhData = () => {
    refFundHistoryListTable.value.$refs.refListTable.refresh();
  };

  const refetchReqData = () => {
    refReqListTable.value.$refs.refListTable.refresh();
  };

  const refetchBudgetData = () => {
    refApprListTable.value.$refs.refListTable.refresh();
  };

  // Refetch all funds histories data
  const refetchData = () => {
    refetchFhData() //fund histories
    refetchReqData() // requests
    refetchBudgetData() // budgets
  }

  watch([fhCurrentPage, fhPerPage], () => {
    refetchFhData();
  });

  watch([reqCurrentPage, reqPerPage], () => {
    refetchReqData();
  });

  watch([budgetCurrentPage, budgetPerPage], () => {
    refetchBudgetData();
  });

  const loadCashRegister = async () => {
    await store.dispatch("fundHistory/loadCashRegister");
  }

  onMounted(() => {
    loadCashRegister();
  })

  const getFundHistories = (ctx, callback) => {
    store.dispatch("fundHistory/fetchFundHistories", {
      filter: ctx.filter,
      perPage: ctx.perPage,
      page: ctx.currentPage,
    }).then(() => {
      callback(fundHistories.value)
    });
  };

  const getRequestsExpenses = (ctx, callback) => {
    const params = {
        filter: ctx.filter,
        perPage: ctx.perPage,
        page: ctx.currentPage,
        status: 'pending',
      }
    axios
      .get("finances/expenses", {params: params})
      .then(({ data }) => {
        callback(data.data);
        totalReq.value = data.meta.total
        reqTotalAmount.value = data.requests_total;
      });
  };

  const getBudgetsExpenses = (ctx, callback) => {
    const params = {
        filter: ctx.filter,
        perPage: ctx.perPage,
        page: ctx.currentPage,
        status: 'approved',
      }
    axios
      .get("finances/expenses", {params: params})
      .then(({ data }) => {
        callback(data.data)
        totalBud.value = data.meta.total
        budgetTotalAmount.value = data.budgets_total;
      });
  };

  const toggleView = () => {
    showCashRegisterList.value = !showCashRegisterList.value
  }


  return {
    getRequestsExpenses,
    getBudgetsExpenses,
    getFundHistories,
    refetchData,
    fundHistoryFields,
    requestsFields,
    approvedFields,
    showCashRegisterList,

    //fund history query properties
    fhPerPage,
    totalFundHistories,
    fhPageOptions,
    fhCurrentPage,
    fhSortBy,
    fhSortDesc,
    fhSortDirection,
    fhFilter,
    refFundHistoryListTable,

    //requests query properties
    reqPerPage,
    reqPageOptions,
    reqCurrentPage,
    reqSortBy,
    reqSortDesc,
    reqSortDirection,
    reqFilter,
    refReqListTable,
    countRequests,

    //budgets query properties
    budgetPerPage,
    budgetPageOptions,
    budgetCurrentPage,
    budgetSortBy,
    budgetSortDesc,
    budgetSortDirection,
    budgetFilter,
    refApprListTable,
    countBudgets,

    refetchFhData,
    refetchReqData,
    fundHistories,
    balance,
    totalDebitAndCredit,

    requests,
    budgets,
    fundHistoryData,
    toggleView,
  };
}
