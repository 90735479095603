<template>
  <validation-observer ref="refFormObserver" #default="{ invalid }">
    <b-modal
      v-model="openModal"
      cancel-variant="outline-secondary"
      ok-title="Enregistrer"
      :ok-disabled="invalid || isLoading"
      cancel-title="Annuler"
      centered
      title="Enregistrer une sortie"
      ref="debit-modal"
      @ok="onSubmit"
      @show="resetModal"
      @hidden="openModal = false"
    >
      <b-form>
        <!-- Debit type -->
        <b-form-group label="Type de sortie" label-for="type">
          <div class="row pb-2">
            <div class="col-6" v-if="$can('make_withdraws', 'Caisse Générale')">
              <b-form-radio
                v-model="transac.type"
                name="type"
                value="other"
                class="custom-control-primary"
              >
                Dépense
              </b-form-radio>
            </div>
            <div
              class="col-6"
              v-if="
                $can(
                  'add_project_request_from_fund_hystory',
                  'Caisse Générale'
                ) || $can('add_request_from_fund_hystory', 'Caisse Générale')
              "
            >
              <b-form-radio
                v-model="transac.type"
                name="type"
                value="expense"
                class="custom-control-warning"
              >
                Requête
              </b-form-radio>
            </div>
          </div>
        </b-form-group>

        <!-- expense type -->
        <b-form-group
          label="Type de dépense"
          label-for="expenseType"
          description="Selectionner le type de dépense"
        >
          <validation-provider
            #default="{ errors }"
            name="expenseType"
            rules="required"
          >
            <b-form-select
              v-model="expense.type"
              :options="expenseTypes"
              @change="getProjects"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <!-- Expense Project -->
        <div v-if="isProjectExpense">
          <b-form-group
            v-if="canMakeProjectExpense || canMakeProjectRequest"
            :label="
              fetchingProject
                ? 'Projet concerné (En cours de récupération...)'
                : 'Projet concerné'
            "
            label-for="expenseProject"
            description="Selectionner le projet"
          >
            <validation-provider
              #default="{ errors }"
              name="expenseProject"
              rules="required"
            >
              <v-select
                v-model="expense.project"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projectsList"
                :reduce="(o) => o.value"
                :clearable="false"
                placeholder="Sélectionnez ou Recherchez votre projet"
                input-id="expenseProject"
                @input="getExpensesList"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche un projet...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
          <div class="mb-2" v-else>
            <b-form-invalid-feedback :state="isProjectExpense ? false : null">
              <feather-icon class="mr-25" icon="AlertCircleIcon" />
              Vous n'êtes pas autorisé à ajouter des dépenses sur projets
            </b-form-invalid-feedback>
          </div>
        </div>

        <!-- Designation -->
        <b-form-group
          label="Désignation"
          label-for="designation"
          description="Indiquez le libellé de votre dépense"
        >
          <validation-provider
            #default="{ errors }"
            name="designation"
            rules="required"
          >
            <vue-autosuggest
              v-if="isProjectExpense"
              :suggestions="designationOptions"
              v-model="transac.designation"
              :input-props="inputProps"
              @selected="onSelectedDesignation"
              :limit="limit"
              @input="onDesignationChange"
            >
              <template slot-scope="{ suggestion }">
                <div class="d-flex align-items-center">
                  <div class="detail">
                    <b-card-text>
                      {{ suggestion.item.designation }}
                    </b-card-text>
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <b-form-input
              v-else
              v-model="transac.designation"
              placeholder="Entrer une désignation..."
            />

            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <!-- sub cash register -->
        <validation-provider
            #default="{ errors }"
            name="Caisse de retrait"
            rules="required"
            v-if="isDebitOfTypeExpense"
        >
          <b-form-group
              label="Caisse de retrait"
              label-for="concerned_sub_cash_register"
          >
            <v-select
                v-model="transac.sub_cash_register"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subCashRegistersList"
                label="name"
                :clearable="false"
                :reduce="(a) => a.uid"
                input-id="concerned_sub_cash_register"
                placeholder="Sélectionner la caisse de retrait..."
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Amount -->
        <b-form-group
          label="Montant"
          label-for="amount"
          description="Insérer le montant de votre dépense"
        >
          <validation-provider
            #default="{ errors }"
            name="montant"
            rules="required"
          >
            <b-form-input
              v-model="amount"
              id="amount"
              type="number"
              placeholder="Le montant de la dépense"
              :disabled="inputProps.disabled"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import store from "@/store";
import axios from "@axios";
import { mapGetters } from "vuex";

import {
  BModal,
  BForm,
  BCardText,
  BFormInvalidFeedback,
  BFormInput,
  BFormGroup,
  BFormRadio,
  BFormSelect,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { onFieldChange } from "@/utils/filter";

// Debit type
const REQUEST_DEBIT = "expense"; // make a expense's request that is send as request for approbation
const DIRECT_DEBIT = "other"; // make a expense that impact directly cash register (I used "other" because client doesn't expess the need to impact directly the cash register)

// Expense type
const ADMIN_EXPENSE = "admin";
const PROJECT_EXPENSE = "project";

export default {
  name: "DebitHandler",
  components: {
    VueAutosuggest,
    BCardText,
    BModal,
    BForm,
    BFormInput,
    BFormSelect,
    BFormRadio,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: ["showContent"],
  data() {
    return {
      isLoading: false,
      fetchingProject: false,
      required,
      alphaNum,

      //transact
      transac: {
        transaction: "debit",
        type: null,
        designation: "",
        amount: null,
        sub_cash_register: null,
      },
      amount: "",

      // expense
      expense: {
        type: ADMIN_EXPENSE,
        project: null,
      },
      expenseTypes: [
        { text: "Administrative", value: ADMIN_EXPENSE },
        { text: "Projet", value: PROJECT_EXPENSE },
      ],
      projectsList: [],

      // expense display properties
      designationOptions: [],
      limit: 10,
    };
  },
  watch: {
    amount: function (val) {
      this.transac.amount = parseInt(val);
    },
  },
  computed: {
    inputProps() {
      return {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: "Entrer une désignation...",
        disabled:
          this.isProjectExpense &&
          !this.canMakeProjectExpense &&
          !this.canMakeProjectRequest,
      };
    },
    openModal: {
      get() {
        return this.showContent;
      },
      set(value) {
        this.$emit("update:showContent", value);
      },
    },
    ...mapGetters("project", {
      expensesData: "expenses",
    }),

    // If user click on Request as Debit
    // isDebitOfTypeExpense() {
    //   return this.transac.type == 'expense'
    // },
    isProjectExpense() {
      return this.expense.type === PROJECT_EXPENSE;
    },

    // check if user has a right to add direct expense on project
    canMakeProjectExpense() {
      return (
        this.$can("make_withdraws", "Caisse Générale") &&
        this.transac?.type === DIRECT_DEBIT
      );
    },
    // check if user has a right to add request on project
    canMakeProjectRequest() {
      return (
        this.$can("add_project_request_from_fund_hystory", "Caisse Générale") &&
        this.transac?.type === REQUEST_DEBIT
      );
    },

    isDebitOfTypeExpense() {
      return this.transac?.type === DIRECT_DEBIT;
    },

    subCashRegistersList() {
      const cashRegister = store.getters["fundHistory/cashRegister"];
      const isSubCashRegister = store.getters["fundHistory/checkDefaultSubCashRegister"];

      if(isSubCashRegister) return ([cashRegister]);

      const subCRs = cashRegister.sub_cash_registers;

      if (subCRs.length <= 0) return [];

      return subCRs;
    },
  },
  methods: {
    getProjects() {
      if (this.isProjectExpense) {
        if (!this.canMakeProjectExpense && !this.canMakeProjectRequest) {
          this.projectsList = [
            {
              label: "Vous n'êtes pas autorisé à récupérer des projets...",
              value: null,
            },
          ];
          return;
        }
        this.fetchingProject = true;
        store.dispatch("project/allProjects").then((projects) => {
          if (projects.length > 0) {
            this.projectsList = projects.map((p) => {
              let strippedHtml = p.brief.replace(/<[^>]+>/g, "");
              return { label: `${p.code} - ${strippedHtml}`, value: p.uid };
            });
          } else {
            this.projectsList = [
              { label: "Aucun projet retrouvé...", value: null },
            ];
          }
          this.fetchingProject = false;
        });
        // .catch()
      }
    },
    getExpensesList(uid) {
      store.dispatch("project/fetchExpenses", { projectId: uid });
      // .then()
      // .catch()
    },
    onSelectedDesignation(option) {
      this.transac.designation = option.item.designation;
    },
    onDesignationChange(text) {
      this.designationOptions = onFieldChange(
        text,
        this.expensesData,
        this.limit,
        "designation"
      );
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.transac.expense = { ...this.expense };
          this.$emit("addTransac", this.transac);
          this.resetModal();
        }
      });
    },
    resetModal() {
      this.isLoading = false;
      this.transac = {
        transaction: "debit",
        type: null,
        designation: "",
        amount: null,
        sub_cash_register: null,
      };
      this.amount = null;
      this.expense = {
        type: ADMIN_EXPENSE,
        project: null,
      };
    },
  },
};
</script>

<style></style>
