<template>
  <div>
    <!-- First Row -->
    <b-row class="mb-3">
      <b-col cols="12" xl="9" lg="8" md="7">
        <fund-list-fund-info-card
          :fund-data="totalDebitAndCredit"
          :balance="balance"
          :fetchFhData="refetchData"
          @toggleFundHistoryView="toggleView"
        />
      </b-col>
      <b-col cols="12" md="5" xl="3" lg="4">
        <fund-list-fund-plan-card :fund-data="fundHistoryData" />
      </b-col>
    </b-row>

    <b-row>
      <b-tabs pills>
        <b-tab
          v-if="$can('read_transactions_fund_history', 'Caisse Générale')"
          active
        >
          <template #title>
            <feather-icon icon="MenuIcon" />
            <span>Historiques</span>
          </template>

          <custom-list
            ref="refFundHistoryListTable"
            concernedList="historiques"
            :items="getFundHistories"
            :total="totalFundHistories"
            :fields="fundHistoryFields"
            :perPage.sync="fhPerPage"
            :pageOptions="fhPageOptions"
            :currentPage.sync="fhCurrentPage"
            :sortBy.sync="fhSortBy"
            :sortDesc.sync="fhSortDesc"
            :sortDirection="fhSortDirection"
            :filter.sync="fhFilter"
          />
        </b-tab>
        <b-tab v-if="$can('read_requests_fund_history', 'Caisse Générale')">
          <template #title>
            <feather-icon icon="CornerRightDownIcon" />
            <span>Requêtes</span>
          </template>

          <custom-list
            ref="refReqListTable"
            concernedList="requests"
            @expenseUpdated="refetchData"
            :items="getRequestsExpenses"
            :total="countRequests"
            :fields="requestsFields"
            :perPage.sync="reqPerPage"
            :pageOptions="reqPageOptions"
            :currentPage.sync="reqCurrentPage"
            :sortBy.sync="reqSortBy"
            :sortDesc.sync="reqSortDesc"
            :sortDirection="reqSortDirection"
            :filter.sync="reqFilter"
          />
        </b-tab>
        <b-tab v-if="$can('read_budgets_fund_history', 'Caisse Générale')">
          <template #title>
            <feather-icon icon="DollarSignIcon" />
            <span>Budgets</span>
          </template>

          <custom-list
            ref="refApprListTable"
            concernedList="budgets"
            @expenseUpdated="refetchData"
            :items="getBudgetsExpenses"
            :total="countBudgets"
            :fields="approvedFields"
            :perPage.sync="reqPerPage"
            :pageOptions="reqPageOptions"
            :currentPage.sync="reqCurrentPage"
            :sortBy.sync="reqSortBy"
            :sortDesc.sync="reqSortDesc"
            :sortDirection="reqSortDirection"
            :filter.sync="reqFilter"
          />
        </b-tab>
      </b-tabs>
    </b-row>

    <!-- Show cash list modal -->
    <cash-register-list-modal
      :toggleModal.sync="showCashRegisterList"
      :cashId="1"
    />
  </div>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from "bootstrap-vue";
import CashRegisterListModal from "./cash-register/CashRegisterListModal.vue";
// import userStoreModule from '../userStoreModule'
import FundListFundInfoCard from "./FundListFundInfoCard.vue";
import FundListFundPlanCard from "./FundListFundPlanCard.vue";
import useFundHistoryList from "../useFundHistoryList";
import CustomList from "@/components/fund-history/customList.vue";

export default {
  name: "FunHistoriesList",
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,

    // Local Components
    FundListFundInfoCard,
    FundListFundPlanCard,
    CustomList,
    CashRegisterListModal,
  },
  setup() {
    const {
      getRequestsExpenses,
      getBudgetsExpenses,
      getFundHistories,
      refetchData,
      fundHistoryFields,
      requestsFields,
      approvedFields,
      showCashRegisterList,

      //fund history
      fhPerPage,
      totalFundHistories,
      fhPageOptions,
      fhCurrentPage,
      fhSortBy,
      fhSortDesc,
      fhSortDirection,
      fhFilter,
      refFundHistoryListTable,

      //requests
      reqPerPage,
      reqPageOptions,
      reqCurrentPage,
      reqSortBy,
      reqSortDesc,
      reqSortDirection,
      reqFilter,
      refReqListTable,
      countRequests,

      //budgets
      budgetPerPage,
      budgetPageOptions,
      budgetCurrentPage,
      budgetSortBy,
      budgetSortDesc,
      budgetSortDirection,
      budgetFilter,
      refApprListTable,
      countBudgets,

      refetchFhData,
      refetchReqData,
      balance,
      totalDebitAndCredit,

      requests,
      budgets,
      fundHistoryData,
      toggleView,
    } = useFundHistoryList();

    return {
      getRequestsExpenses,
      getBudgetsExpenses,
      getFundHistories,
      refetchData,
      fundHistoryFields,
      requestsFields,
      approvedFields,
      showCashRegisterList,

      //fund history
      fhPerPage,
      totalFundHistories,
      fhPageOptions,
      fhCurrentPage,
      fhSortBy,
      fhSortDesc,
      fhSortDirection,
      fhFilter,
      refFundHistoryListTable,

      //requests
      reqPerPage,
      reqPageOptions,
      reqCurrentPage,
      reqSortBy,
      reqSortDesc,
      reqSortDirection,
      reqFilter,
      refReqListTable,
      countRequests,

      //budgets
      budgetPerPage,
      budgetPageOptions,
      budgetCurrentPage,
      budgetSortBy,
      budgetSortDesc,
      budgetSortDirection,
      budgetFilter,
      refApprListTable,
      countBudgets,

      refetchFhData,
      refetchReqData,
      balance,
      totalDebitAndCredit,

      requests,
      budgets,
      fundHistoryData,
      toggleView,
    };
  },
  data() {
    return {
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Transaction ligne: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
  },
};
</script>
