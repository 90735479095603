<template>
  <b-card no-body class="border-default">
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0 text-capitalize text-truncate" style="max-width: 60%">
        Requête
      </h5>
      <b-badge variant="light-primary text-capitalize">
        {{ amountToCurrency(localFund.requests_total) }}
      </b-badge>
      <!-- <small class="text-muted w-50">{{ projectData.created_at }}</small> -->
    </b-card-header>
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0 text-capitalize text-truncate" style="max-width: 60%">
        Budget
      </h5>
      <b-badge variant="light-primary text-capitalize">
        {{ amountToCurrency(localFund.budgets_total) }}
      </b-badge>
      <!-- <small class="text-muted w-50">{{ projectData.created_at }}</small> -->
    </b-card-header>

    <b-card-body>
      <!-- <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">Budget</span>
        </li>
        <li class="my-25">
          <span class="align-middle">10 GB T</span>
        </li>
      </ul> -->
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from "bootstrap-vue";
import { amountToCurrency } from "@/utils/formatAmount";

export default {
  props: {
    fundData: {
      type: Object,
      required: true,
    },
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
  },
  data() {
    return {
      localFund: {
        requests_total: 0,
        budgets_total: 0,
      },
    };
  },
  watch: {
    fundData: function (val) {
      this.localFund = { ...val };
    },
  },
  methods: {
    amountToCurrency,
  }
};
</script>

<style></style>
