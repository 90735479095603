<template>
  <b-modal
    id="cash-register-modal"
    v-model="toggleCashRegisterListModal"
    ok-only
    ok-title="Fermer"
    centered
    title="Options"
    :ok-disabled="isLoading"
    :cancel-disabled="isLoading"
  >
    <component
      :is="currentComponent"
      :loading.sync="isLoading"
      @toggle-component="setCashRegisterOptionsComponent"
      @cash-register-handled="showToast"
    />
  </b-modal>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import ToastNotificationContent from "@core/components/toastification/ToastificationContent.vue";
import CashRegisterOptionsCashRegistersList from "./cash-register-options/CashRegisterOptionsCashRegistersList.vue";
import CashRegisterOptionsCashRegisterForm from "./cash-register-options/CashRegisterOptionsCashRegisterForm.vue";
import CashRegisterOptionsSubCashRegisterForm from "./cash-register-options/CashRegisterOptionsSubCashRegisterForm.vue";

export default {
  name: "cashRegisterListModal",
  components: {
    CashRegisterOptionsCashRegistersList,
    CashRegisterOptionsCashRegisterForm,
    CashRegisterOptionsSubCashRegisterForm,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    toggleModal: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      isLoading: false,
      optionsComponent: "Cash Registers List",
    };
  },
  watch: {
    toggleCashRegisterListModal: {
      handler: function (isOpened) {
        if (!isOpened) this.optionsComponent = "Cash Registers List";
      },
      immediate: true,
    },
  },
  computed: {
    toggleCashRegisterListModal: {
      get: function () {
        return this.toggleModal;
      },
      set: function (val) {
        this.$emit("update:toggleModal", val);
      },
    },
    currentComponent() {
      const slug = this.optionsComponent.replaceAll(" ", "-").toLowerCase();
      return "cash-register-options-" + slug;
    },
  },
  methods: {
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastNotificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    },
    setCashRegisterOptionsComponent(component) {
      if (!component) this.optionsComponent = "Cash Registers List";
      this.optionsComponent = component;
    },
  },
};
</script>
