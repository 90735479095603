<template>
  <validation-observer
    ref="refFormSubCashRegisterObserver"
    #default="{ invalid }"
  >
    <b-form @submit.prevent="onSubmit" @reset="resetForm">
      <b-card class="card-cash-register-parent shadow-none mx-0" no-body>
        <b-card-header>
          <b-card-title>
            {{ currentId ? "Modifier" : "Ajouter" }} Sous-Caisse
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <!-- Field: Designation -->
          <validation-provider
            #default="{ errors }"
            name="designation"
            rules="required"
          >
            <b-form-group label="Désignation" label-for="designation">
              <b-form-input
                v-model="subCashRegisterData.name"
                id="designation"
                placeholder="Veuillez entre le nom de votre sous-caisse."
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Fund Type -->
          <validation-provider
            #default="{ errors }"
            name="fund_type"
            rules="required"
            v-if="!currentId"
          >
            <b-form-group label="Type de caisse :" label-for="fund_type">
              <v-select
                v-model="subCashRegisterData.fund_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fundTypesList"
                label="name"
                :reduce="(val) => val.slug"
                :selectable="
                  (option) => !option.slug.includes('nothing_selected')
                "
                :clearable="false"
                input-id="fund_type"
                placeholder="Sélectionner le type de caisse..."
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group v-else label="Type de caisse :" label-for="fund_type">
            <b-form-input
              id="fund_type"
              :value="subCashRegisterData.fund_type.name"
              disabled
            />
          </b-form-group>

          <!-- Field: Sub cas register manager -->
          <validation-provider #default="{ errors }" name="manager">
            <b-form-group label="Gestionnaire :" label-for="manager">
              <v-select
                v-model="subCashRegisterData.manager_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="usersList"
                label="name"
                :reduce="(val) => val.uid"
                :clearable="false"
                input-id="manager"
                placeholder="Sélectionner le gestionnaire de la sous caisse..."
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Description -->
          <validation-provider
            #default="{ errors }"
            name="description"
            rules=""
          >
            <b-form-group label="Description :" label-for="description">
              <b-form-textarea
                id="description"
                v-model="subCashRegisterData.description"
                placeholder="Description..."
                rows="1"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-card-body>
        <b-card-footer class="d-flex justify-content-end">
          <b-button
            size="sm"
            class="mr-1"
            type="submit"
            variant="primary"
            :disabled="isLoading || invalid"
          >
            <b-spinner v-if="isLoading" small />
            {{ currentId ? "Mettre à jour" : "Enregistrer" }}
          </b-button>
          <b-button
            size="sm"
            type="reset"
            variant="outline-secondary"
            :disabled="isLoading"
            >Annuler
          </b-button>
        </b-card-footer>
      </b-card>
    </b-form>
  </validation-observer>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations, mapActions } =
    createNamespacedHelpers("subCashRegister");

import {
  BButton,
  BSpinner,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import vSelect from "vue-select";

export default {
  name: "CashRegisterOptionsSubCashRegisterForm",
  emit: ["toggle-component", "sub-cash-register-handled"],
  props: ["loading"],
  components: {
    BFormTextarea,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BButton,
    BSpinner,
    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      subCashRegisterData: {
        name: "",
        description: "",
        fund_type: null,
        manager: null,
      },
    };
  },
  watch: {
    currentSubCashRegister: {
      handler: function (val) {
        this.subCashRegisterData = { ...val };
      },
      immediate: true,
      deep: true,
    },
    getError: {
      handler: function (val) {
        if (val) {
          this.sendSubCashRegisterAlert(val.message, "danger");
          this.handleErrors(val.error);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      currentSubCashRegister: "sub_cash_register",
      cashRegister: "cash_register",
      fundTypesList: "fund_types",
      usersList: "managers",
      getError: "errors",
    }),
    isLoading: {
      get: function () {
        return this.loading;
      },
      set: function (val) {
        this.$emit("update:loading", val);
      },
    },
    currentId() {
      return this.currentSubCashRegister?.uid;
    },
  },
  mounted() {
    this.fetchSubCashRegisterProperties({ props: ["managers"] })
  },
  methods: {
    ...mapActions(["fetchSubCashRegisterProperties", "addSubCashRegister", "updateSubCashRegister"]),
    ...mapMutations({
      setError: "SET_ERRORS",
    }),
    onSubmit: async function () {
      this.isLoading = true;
      this.setError(null);
      let response = {};

      try {
        const { uid, name, description, fund_type, manager_id } = this.subCashRegisterData;
        let payload = {
          cash_register: this.cashRegister?.uid,
          data: { name, description, fund_type, manager_id }
        };
        // check if data has uid to be able to direct submission to the Add / Update action
        if (uid) {
          payload.id = uid;
          response = await this.updateSubCashRegister(payload);
        } else {
          response = await this.addSubCashRegister(payload);
        }

        // send notification and close the cash register form
        this.sendSubCashRegisterAlert(response.message, "success");
        this.resetForm();
      } catch (e) {
        // this.sendCashRegisterAlert(
        //   "Une erreur est survenue lors de la mise à jour de la caisse.",
        //   "danger"
        // );
      } finally {
        this.setError(null);
        this.isLoading = false;
      }
    },
    resetForm() {
      this.subCashRegisterData = { ...this.currentSubCashRegister };
      this.$emit("toggle-component", "Cash Registers List");
    },
    sendSubCashRegisterAlert(message, type) {
      this.$emit("sub-cash-register-handled", message, "AlertTriangleIcon", type);
    },
    handleErrors(errors) {
      for (const [field, message] of Object.entries(errors)) {
        this.$refs.refFormSubCashRegisterObserver.setErrors({ [field]: message[0] });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
