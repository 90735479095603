<template>
  <validation-observer ref="refCreditFormObserver" #default="{ invalid }">
    <b-modal
      v-model="openModal"
      cancel-variant="outline-secondary"
      ok-title="Enregistrer"
      :ok-disabled="invalid"
      cancel-title="Annuler"
      centered
      title="Enregistrer une entrée"
      ref="credit-modal"
      @ok="onSubmit"
      @show="resetModal"
      @hidden="openModal = false"
    >
      <b-form>
        <!-- Designation -->
        <validation-provider
          #default="{ errors }"
          name="designation"
          rules="required"
        >
          <b-form-group
            label="Désignation"
            label-for="designation"
            description="Indiquez le libellé de votre entrée"
          >
            <b-form-input v-model="transac.designation" />

            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- sub cash register -->
        <validation-provider
          #default="{ errors }"
          name="Caisse de dépôt"
          rules="required"
        >
          <b-form-group
            label="Caisse de dépôt"
            label-for="concerned_sub_cash_register"
          >
            <v-select
              v-model="transac.sub_cash_register"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subCashRegistersList"
              label="name"
              :clearable="false"
              :reduce="(a) => a.uid"
              input-id="concerned_sub_cash_register"
              placeholder="Sélectionner la caisse de dépôt..."
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Amount -->
        <validation-provider
            #default="{ errors }"
            name="montant"
            rules="required"
        >
          <b-form-group
              label="Montant"
              label-for="amount"
              description="Insérer le montant de votre entrée"
          >
            <b-form-input
                v-model="amount"
                id="amount"
                type="number"
                placeholder="Le montant de l'entrée"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>

      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import store from "@/store";
import axios from "@axios";
import vSelect from "vue-select";

import {
  BModal,
  BForm,
  BFormInvalidFeedback,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";

import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { onFieldChange } from "@/utils/filter";

export default {
  name: "DebitHandler",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: ["showContent"],
  data() {
    return {
      required,
      alphaNum,

      //transact
      transac: {
        transaction: "credit",
        type: "other",
        designation: "",
        amount: null,
        sub_cash_register: null,
      },
      amount: null,
    };
  },
  watch: {
    amount: function (val) {
      this.transac.amount = parseInt(val);
    },
  },
  computed: {
    openModal: {
      get() {
        return this.showContent;
      },
      set(value) {
        this.$emit("update:showContent", value);
      },
    },

    subCashRegistersList() {
      const cashRegister = store.getters["fundHistory/cashRegister"];
      const isSubCashRegister = store.getters["fundHistory/checkDefaultSubCashRegister"];

      if(isSubCashRegister) return ([cashRegister]);

      const subCRs = cashRegister.sub_cash_registers;

      if (subCRs.length <= 0) return [];

      return subCRs;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refCreditFormObserver.validate().then((success) => {
        if (success) {
          this.$emit("addTransac", this.transac);
          this.resetModal();
        }
      });
    },
    resetModal() {
      this.transac = {
        transaction: "credit",
        type: "other",
        designation: "",
        amount: null,
        sub_cash_register: null,
      };
      this.amount = null;
    },
  },
};
</script>

<style></style>
