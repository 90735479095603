<template>
  <b-card
    id="cr-parent"
    class="card-cash-register-parent shadow-none mx-0"
    no-body
  >
    <!--      Card header-->
    <b-card-header class="px-0">
      <b-card-title>
        <b-button
          v-show="!isMainView"
          variant="primary"
          class="btn-icon"
          size="sm"
          @click="$emit('go-back')"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
        <span class="font-weight-bold">
          {{ currTitle }}
        </span>
      </b-card-title>
      <!-- dropdown actions -->
      <b-dropdown size="sm" variant="link" toggle-class="p-0" no-caret dropleft>
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="cursor-pointer"
          />
        </template>
        <!--        This button is only for main cash register-->
        <b-dropdown-item
          v-show="isMainView"
          @click="$emit('open-cash-register-form', 'add')"
        >
          <feather-icon icon="PlusIcon" />
          <span class="align-middle ml-50">Ajouter Caisse</span>
        </b-dropdown-item>
        <!--        Those buttons are only available for sub cash register-->
        <!--        start: add sub cash register-->
        <b-dropdown-item
          v-show="!isMainView && listLvl <= 1"
          @click="$emit('open-cash-register-form', 'sub-add')"
        >
          <feather-icon icon="PlusIcon" />
          <span class="align-middle ml-50">Ajouter une sous caisse</span>
        </b-dropdown-item>
        <!--        start: edit cash register-->
        <div v-show="!isMainView">
          <b-dropdown-item
            v-show="listLvl <= 1"
            @click="$emit('open-cash-register-form', 'edit')"
          >
            <feather-icon icon="Edit2Icon" />
            <span class="align-middle ml-50">Modifier</span>
          </b-dropdown-item>
          <!--        start: edit sub cash register-->
          <b-dropdown-item
            v-show="listLvl > 1"
            @click="$emit('open-cash-register-form', 'sub-edit')"
          >
            <feather-icon icon="Edit2Icon" />
            <span class="align-middle ml-50">Modifier sous caisse</span>
          </b-dropdown-item>
        </div>
        <!--        start disable or delete cash register-->
        <b-dropdown-item
          v-show="!isMainView"
          variant="danger"
          @click="deleteCashRegister"
        >
          <feather-icon :icon="hasSubCR ? 'ToggleRightIcon' : 'TrashIcon'" />
          <span class="align-middle ml-50">{{
            hasSubCR ? "Désactiver" : "Supprimer"
          }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <!--      Card body-->
    <b-card-body class="px-0">
      <div v-if="isLoading" class="d-flex justify-content-center my-3">
        <b-spinner style="width: 4rem; height: 4rem" />
      </div>
      <b-list-group v-else flush>
        <b-list-group-item
          v-for="(cashRegister, i) in cashRegistersList"
          :key="cashRegister.uid"
          @click="$emit('show-sub-cash-registers', cashRegister)"
          class="d-flex justify-content-between align-items-center"
          button
        >
          <span>{{ i + 1 }}. {{ cashRegister.name }}</span>
          <b-badge variant="primary" pill class="badge-round">
            {{ amountToCurrency(cashRegister.amount) }}
          </b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from "bootstrap-vue";

import { amountToCurrency } from "@/utils/formatAmount";

import { createNamespacedHelpers } from "vuex";
import ToastNotificationContent from "@core/components/toastification/ToastificationContent.vue";

const { mapGetters: mapCashRegisterGetters, mapActions } =
  createNamespacedHelpers("cashRegister");

export default {
  name: "CashRegistersList",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,

    // eslint-disable-next-line vue/no-unused-components
    ToastNotificationContent,
  },
  emit: ["open-cash-register-form", "show-sub-cash-registers", "go-back"],
  props: {
    cashRegistersTitle: {
      type: String,
      default: "Liste des Caisses",
    },
    listLvl: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapCashRegisterGetters({
      cashRegisters: "all",
      currentCashRegister: "cash_register",
      subCashRegisters: "sub_cash_registers",
    }),
    currTitle() {
      if(!this.isMainView) {
        if (this.listLvl > 1) {
          const subCR = this.$store.getters["subCashRegister/sub_cash_register"];
          return subCR?.name;
        }
        return this.currentCashRegister.name
      }
      return this.cashRegistersTitle;
    },
    hasSubCR() {
      if (!this.currentCashRegister.hasOwnProperty("sub_cash_registers"))
        return false;
      return (
        !this.isMainView &&
        this.currentCashRegister.sub_cash_registers.length > 0
      );
    },
    isMainView() {
      return this.listLvl === 0;
    },
    cashRegistersList() {
      if (this.isMainView) {
        return this.cashRegisters;
      }
      return this.listLvl <= 1 ? this.subCashRegisters : [];
    },
  },
  methods: {
    ...mapActions(["destroyCashRegister"]),
    amountToCurrency,
    async deleteCashRegister() {
      try {
        const { status, data } = await this.destroyCashRegister(
          this.currentCashRegister?.uid
        );
        if (status === 200) {
          this.$emit("go-back");
          this.showToast(data.message, "AlertTriangleIcon", "success");
        } else {
          this.showToast(data.error, "AlertTriangleIcon", "danger");
        }
      } catch (e) {
        this.showToast(
          "Une erreur est survenue lors de la suppression de la caisse!",
          "AlertTriangleIcon",
          "danger"
        );
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastNotificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    },
  },
};
</script>
