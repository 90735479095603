<template>
  <div>
    <cash-registers-list
      :list-lvl="cashRegisterLvl"
      :is-loading="loading"
      @open-cash-register-form="openCashRegisterForm"
      @show-sub-cash-registers="showSubCashRegisters"
      @go-back="goBack"
    />
  </div>
</template>
<script>
import { amountToCurrency } from "@/utils/formatAmount";

import { createNamespacedHelpers } from "vuex";
import CashRegistersList from "@/views/apps/fund-history/fund-histories-list/cash-register/cash-register-options/CashRegistersList.vue";

const {
  mapGetters: mapCashRegisterGetters,
  mapMutations: mapCashRegisterMutations,
  mapActions: mapCashRegisterActions,
} = createNamespacedHelpers("cashRegister");

const {
  mapGetters: mapSubCashRegisterGetters,
  mapMutations: mapSubCashRegisterMutations,
  mapActions: mapSubCashRegisterActions,
} = createNamespacedHelpers("subCashRegister");

export default {
  name: "CashRegisterOptionsCashRegisterList",
  props: ["loading"],
  components: {
    CashRegistersList,
  },
  data() {
    return {
      cashRegisterLvl: 0,
    };
  },
  computed: {
    ...mapCashRegisterGetters({
      cashRegisters: "all",
      currentCashRegister: "cash_register",
      subCashRegisters: "sub_cash_registers",
    }),
    isLoading: {
      get: function () {
        return this.loading;
      },
      set: function (val) {
        this.$emit("update:loading", val);
      },
    },
  },
  mounted() {
    this.getAllCahRegisters();
  },
  methods: {
    amountToCurrency,
    // cash register
    ...mapCashRegisterActions(["allCashRegisters", "fetchCashRegister"]),
    ...mapCashRegisterMutations({
      setCurrentCashRegister: "SET_RESOURCE",
    }),
    // sub cash register
    ...mapSubCashRegisterActions(["fetchSubCashRegisterProperties"]),
    ...mapSubCashRegisterMutations({
      setCurrentSubCashRegister: "SET_RESOURCE",
      setSubMainCR: "SET_MAIN_CASH_REGISTER",
    }),

    async getAllCahRegisters() {
      try {
        this.isLoading = true;
        await this.allCashRegisters();
      } catch (e) {
        this.$emit(
          "cash-register-handled",
          "Erreur de récupération de la liste des caisses",
          "AlertTriangleIcon",
          "danger"
        );
      } finally {
        this.isLoading = false;
      }
    },
    async getSubListOfCurrentCR() {
      try {
        this.isLoading = true;
        await this.fetchCashRegister(this.currentCashRegister.uid);
      } catch (e) {
        this.$emit(
          "cash-register-handled",
          "Erreur de récupération de la liste des sous caisses",
          "AlertTriangleIcon",
          "danger"
        );
      } finally {
        this.isLoading = false;
      }
    },
    showSubCashRegisters(cashRegister) {
      // check if the click cash register is main cash register or sub cash register
      // and then toggle the corresponding action
      this.cashRegisterLvl += 1; // set the list level

      if(this.cashRegisterLvl <= 1) {
        this.setCurrentCashRegister(cashRegister);
         this.getSubListOfCurrentCR(cashRegister);
      } else {
        // on second level of list we don't need to fetch sub cash register list for a sub list itself
        this.setCurrentSubCashRegister(cashRegister);
      }
    },
    blankCashRegisterForm() {
      return {
        name: "",
        description: "",
      };
    },
    // reset cash register object to default in store
    initCashRegisterForm() {
      let param = this.blankCashRegisterForm();
      this.setCurrentCashRegister(param);
    },
    //reset su cash register object && main cash register to default in store
    initSubCashRegisterForm() {
      let param = this.blankCashRegisterForm();
      this.setCurrentSubCashRegister(param);
    },
    openCashRegisterForm(action) {
      switch (action) {
        case "add":
          this.initCashRegisterForm();
          this.$emit("toggle-component", "Cash Register Form");
          break;
        case "edit":
          this.$emit("toggle-component", "Cash Register Form");
          break;
        case "sub-add":
          this.setSubMainCR(this.currentCashRegister);

          this.fetchSubCashRegisterProperties({ props: ["fund_types"] });

          this.initSubCashRegisterForm();
          this.$emit("toggle-component", "Sub Cash Register Form");
          break;
        case "sub-edit":
          this.setSubMainCR(this.currentCashRegister);

          this.fetchSubCashRegisterProperties({ props: ["fund_types"] });
          this.$emit("toggle-component", "Sub Cash Register Form");
          break;
        default:
          break;
      }
    },
    // on go back check if it is main cash registers level
    // or sub cash registers level and then reset the according form
    goBack() {
      this.cashRegisterLvl -= 1;
      if (this.cashRegisterLvl === 0) {
        this.initCashRegisterForm();
      } else {
        this.initSubCashRegisterForm();
        this.setSubMainCR(null);
      }
    },
  },
};
</script>
