<template>
  <b-card>
    <b-row>
      <!-- Fund History Info: Left col -->
      <b-col
        cols="12"
        xl="9"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Fund History Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :text="avatarText(crName)"
            :variant="`light-${resolveFundHistoryVariant()}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ localBalance }}
                <feather-icon
                  class="cursor-pointer ml-1"
                  :icon="balanceToggleIcon"
                  @click="toggleBalanceVisibility"
                />
              </h4>
              <b-button
                v-if="!isSubCashLoaded"
                variant="link"
                class="p-0"
                @click="showAccountDetails = true"
              >
                <span class="card-text">{{ crName }}</span>
              </b-button>
            </div>
            <cash-register-details-modal
                v-if="!isSubCashLoaded"
              :toggleModal.sync="showAccountDetails"
            />
            <transac-modal @transacUpdated="fetchFhData" />
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col v-if="!isSubCashLoaded" cols="12" xl="3">
        <!-- Cash register actions -->
        <div class="d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle"
            @click="$emit('toggleFundHistoryView')"
          >
            <feather-icon icon="RepeatIcon" />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import TransacModal from "@/components/fund-history/TransacModal.vue";
import { amountToCurrency } from "@/utils/formatAmount";
import CashRegisterDetailsModal from "./cash-register/cash-register-details-modal/CashRegisterDetailsModal.vue";

import { computed, ref, watch } from "@vue/composition-api";
import store from "@/store";

export default {
  name: "FundListInfoCard",
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    TransacModal,
    CashRegisterDetailsModal,
  },
  directives: {
    Ripple,
  },
  props: {
    fundData: {
      type: Object,
      required: true,
    },
    balance: Number,
    fetchFhData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showBalance: false,
      showAccountDetails: false,
      selectedCashRegister: null,
    };
  },
  setup() {
    const resolveFundHistoryVariant = () => {
      const variants = ["primary", "info", "success"];

      return variants[Math.floor(Math.random() * variants.length)];
    };

    const showAccountDetails = ref(false);
    const showBalance = ref(false);

    watch(showAccountDetails, (isOpened) => {
      if (isOpened) {
        // TODO: ?
      }
    });
    const toggleBalanceVisibility = () =>
      (showBalance.value = !showBalance.value);

    const cashRegister = computed(
      () => store.getters["fundHistory/cashRegister"]
    );

    const crName = computed(() =>
      cashRegister.value ? cashRegister.value.name : "_____"
    );
    const isSubCashLoaded = computed(() => {
      return store.getters["fundHistory/checkDefaultSubCashRegister"]
    });

    const localBalance = computed(() => {
      if (showBalance.value) return amountToCurrency(cashRegister.value.amount);
      return "********** FCFA";
    });

    const balanceToggleIcon = computed(() => {
      return showBalance.value ? "EyeIcon" : "EyeOffIcon";
    });

    return {
      localBalance,
      cashRegister,
      crName,
      showAccountDetails,
      isSubCashLoaded,

      avatarText,
      balanceToggleIcon,
      toggleBalanceVisibility,
      resolveFundHistoryVariant,
      amountToCurrency,
    };
  },
};
</script>

<style></style>
